import React, { ReactNode } from 'react';
import classnames from 'classnames';
import InfoIcon from '@/assets/images/icons/info.svg';
import Image from '@/components/atoms/image';
import './style.scss';

export type BannerProps = {
  className?: string;
  classNameMessage?: string;
  message: string;
  children?: ReactNode;
};

const Banner = ({
  message,
  className,
  classNameMessage,
  children
}: BannerProps) => {
  return (
    <div className={classnames('banner', className)}>
      <Image src={InfoIcon} alt="" />
      <div className={classnames('banner__message', classNameMessage)}>
        {message}
      </div>
      {children}
    </div>
  );
};

export default Banner;
