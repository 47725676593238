import React from 'react';
import Modal from '@/components/organisms/modal';
import StoreLocator from '@/components/organisms/storeLocator';
import SimpleFullMainNav from '@/lib/api/categories';
import PdvModel from '@/lib/model/pdv';
import { findCategory } from '@/lib/utils/category';
import { setPdvCookie } from '@/lib/utils/pdv';
import Category from '@/lib/model/category';
import ProductApi from '@/lib/api/product';
import getConfigValue from '@/lib/config';
import Product from '@/lib/model/product';
import t from '@/lib/i18n';

export type SeoStoreLocProps = {
  className?: string;
  classNameTitle?: string;
  classNameText?: string;
  title?: string;
  content?: string;
} & RedirectSeo;

export type RedirectSeo = {
  redirectId?: string | null;
  redirectType?: 'plp' | 'pdp' | 'shop' | null;
  callback?: null | (() => void);
};

export const redirectPlp = async (
  categoryId: string,
  pdvRef: string,
  urlQueryParams = ''
) => {
  const data = await Promise.all([
    SimpleFullMainNav.getNavByPdv(pdvRef),
    SimpleFullMainNav.getParentCategory(categoryId)
  ]);
  const pdvCategories: Array<Category> = data[0]?.tree || [];
  const seoParentCat: Category | null = data[1];

  const cat = findCategory(categoryId, pdvCategories);
  if (cat?.link) {
    window.location.href = `${cat.link}${urlQueryParams}`;
    return;
  }
  if (seoParentCat) {
    const parentCat: Category | null = findCategory(
      seoParentCat.id,
      pdvCategories
    );
    const seeAllCat: Category | undefined = parentCat?.children?.find(
      (categoryTemp: Category) => categoryTemp.slug === 'voir-tout'
    );
    if (seeAllCat?.link) {
      window.location.href = `${seeAllCat.link}${urlQueryParams}`;
      return;
    }
  }
  window.location.href = `/home${urlQueryParams}`;
};

export const redirectPdp = async (
  ean: string,
  pdvRef: string,
  urlQueryParams = ''
) => {
  let product: Product;
  try {
    product = await ProductApi.findByEan(pdvRef, ean);
  } catch (error: any) {
    let productSeo: Product | null = null;
    try {
      productSeo = await ProductApi.findByEan(
        getConfigValue('VIRTUAL_PDV_REF', '00000').toString(),
        ean
      );
    } catch (err) {
      window.location.href = `/home${urlQueryParams}`;
    }
    if (productSeo?.famillyId) {
      redirectPlp(productSeo?.famillyId?.toString(), pdvRef);
    } else {
      window.location.href = `/home${urlQueryParams}`;
    }
    return;
  }
  if (product.available && product.stock && product.stock > 5) {
    window.location.href = `${product.url}${urlQueryParams}`;
    return;
  }
  redirectPlp(product.famillyId.toString(), pdvRef);
};

export const redirectShop = async (
  boutiqueId: string,
  pdvRef: string,
  urlQueryParams = ''
) => {
  const list = await ProductApi.findByShop(pdvRef, boutiqueId);
  if (list.products.length) {
    window.location.href = `/shop/${boutiqueId}${urlQueryParams}`;
  } else {
    window.location.href = `/home${urlQueryParams}`;
  }
};

const SeoStoreLoc = ({
  className = 'plp__modal',
  classNameTitle = 'plp__modal__title',
  classNameText = 'plp__modal__text',
  redirectId,
  redirectType,
  title = t('header.storeInfo.chooseStore.title'),
  content = t('header.storeInfo.chooseStoreSEO.content'),
  callback = null
}: SeoStoreLocProps) => {
  const redirectSeo = (pdv: PdvModel) => {
    if (callback) callback();
    if (redirectType === 'plp' && redirectId) {
      setPdvCookie(pdv);
      redirectPlp(redirectId, pdv.ref);
      return;
    }
    if (redirectType === 'pdp' && redirectId) {
      setPdvCookie(pdv);
      redirectPdp(redirectId, pdv.ref);
    }
    if (redirectType === 'shop' && redirectId) {
      setPdvCookie(pdv);
      redirectShop(redirectId, pdv.ref);
    }
  };
  return (
    <Modal
      className={className}
      open
      scrollable={false}
      onClose={() => null}
      closable={false}
    >
      <h3 className={classNameTitle}>{title}</h3>
      <p className={classNameText}>{content}</p>
      <StoreLocator
        withInStore={false}
        withDrive
        withDelivery
        redirect={{
          auto: false,
          onSelect: redirectSeo
        }}
      />
    </Modal>
  );
};

export default SeoStoreLoc;
