import React from 'react';
import Layout from '@/components/organisms/layout';
import LocalisationContent, { LocalisationContentProps } from './content';

export type LocalisationProps = {
  redirectField?: string | null;
  id?: string | null;
};

const Localisation = ({ redirectField, id }: LocalisationContentProps) => {
  return (
    <Layout
      header={null}
      content={<LocalisationContent redirectField={redirectField} id={id} />}
      footer={null}
    />
  );
};

export default Localisation;
