import React from 'react';

type PageContextType = {
  referer?: string;
  pageView?: string;
};

const defaultValues: PageContextType = {
  referer: '',
  pageView: ''
};

const PageContext = React.createContext(defaultValues);

export default PageContext;
