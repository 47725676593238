import React, { useState } from 'react';
import t from '@/lib/i18n';
import CommonSprite from '@/assets/images/sprites/common.svg';
import loaclisationGif from '@/assets/images/icons/localisation.gif';
import { useIsomorphicLayoutEffect } from 'react-use';
import { getPdvRef } from '@/lib/hooks/usePdv';
import { setPdvCookie } from '@/lib/utils/pdv';
import pdvApi from '@/lib/api/pdv';
import Pdv from '@/lib/model/pdv';
import { useRouter } from 'next/router';
import SeoStoreLoc, {
  redirectPlp,
  redirectPdp,
  redirectShop
} from '@/components/organisms/seoStoreLoc';
import './style.scss';

const optionsGeoLoc = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

export const searchByGeolocation = async (
  lat: number,
  lng: number
): Promise<Array<Pdv>> => {
  return (await pdvApi.getPdvsByArea({ lat, lng }, true, 1, 2)) || null;
};

export type LocalisationContentProps = {
  redirectField?: string | null;
  id?: string | null;
};

const LocalisationContent = ({
  redirectField,
  id
}: LocalisationContentProps) => {
  const router = useRouter();
  let urlQueryParams: string = router.asPath.split('?')?.[1] || '';
  urlQueryParams = urlQueryParams ? `?${urlQueryParams}` : '';
  const [openSeoStoreLoc, setOpenSeoStoreLoc] = useState(false);
  const redirect = (pdvRef: string) => {
    switch (redirectField) {
      case 'boutique':
        if (id && pdvRef) redirectShop(id, pdvRef, urlQueryParams);
        break;
      case 'rayon':
      case 'famille':
        if (id && pdvRef) redirectPlp(id, pdvRef, urlQueryParams);
        break;
      case 'produit':
        if (id && pdvRef) redirectPdp(id, pdvRef, urlQueryParams);
        break;
      default:
        window.location.href = `/home${urlQueryParams}`;
    }
  };

  const successGeoLoc = async (position: any) => {
    try {
      const pdvs: Array<Pdv> = await searchByGeolocation(
        position.coords.latitude,
        position.coords.longitude
      );
      if (pdvs[0].ref) {
        setPdvCookie(pdvs[0]);
        redirect(pdvs[0].ref);
      } else {
        setOpenSeoStoreLoc(true);
      }
    } catch (error: any) {
      setOpenSeoStoreLoc(true);
    }
  };

  const errorGeoLoc = () => {
    setOpenSeoStoreLoc(true);
  };

  const getRedirectType = (): 'plp' | 'pdp' | 'shop' | null => {
    switch (redirectField) {
      case 'boutique':
        return 'shop';
      case 'rayon':
      case 'famille':
        return 'plp';
      case 'produit':
        return 'pdp';
      default:
        return null;
    }
  };

  useIsomorphicLayoutEffect(() => {
    const pdvRef = getPdvRef();
    if (pdvRef) {
      redirect(pdvRef);
    } else {
      try {
        window.navigator.geolocation.getCurrentPosition(
          successGeoLoc,
          errorGeoLoc,
          optionsGeoLoc
        );
      } catch (error: any) {
        setOpenSeoStoreLoc(true);
      }
    }
  }, []);

  return (
    <>
      <div className="localisation">
        <h1 className="localisation__title">{t('localisation.welcome')}</h1>
        <svg width="300" className="fill--white">
          <use xlinkHref={`${CommonSprite}#logo`} />
        </svg>
        <img
          className="localisation__gif"
          alt="Loading localisation"
          src={loaclisationGif}
        />
        <h2 className="localisation__subTitle">{t('localisation.wait')}</h2>
        <p className="localisation__text">{t('localisation.search')}</p>
      </div>
      {openSeoStoreLoc && (
        <SeoStoreLoc redirectId={id || null} redirectType={getRedirectType()} />
      )}
    </>
  );
};

export default LocalisationContent;
