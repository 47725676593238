import getConfigValue from '@/lib/config';
import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';

class MapsApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({
      baseURL: getConfigValue('GOOGLE_MAPS_URL', '').toString()
    });
  }

  getAdresses = async (searchKeyword: string): Promise<any> => {
    let response = null;

    try {
      response = await fetch(
        `/api/google/maps/api/place/textsearch/json?query=${searchKeyword}&region=PT`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to get adresses from google api',
        error
      });
    }
    const res = await response?.json();

    return res;
  };

  geocode = async (lat: number, lng: number): Promise<any> => {
    let response = null;
    try {
      response = await fetch(
        `/api/google/maps/api/geocode/json?latlng=${lat},${lng}&region=PT&language=pt`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to get adresses from geolocation',
        error
      });
    }
    const res = await response?.json();

    return res;
  };
}

export default new MapsApi();
