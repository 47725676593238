import React, { useState, ChangeEvent } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import PdvApi from '@/lib/api/pdv';
import Modal from '@/components/organisms/modal';
import FormInput from '@/components/atoms/formInput';

type PrivateStoreCheckProps = {
  pdvRef: string;
  onValidated: () => void;
  onClose: () => void;
};

const PrivateStoreCheck = ({
  pdvRef,
  onValidated,
  onClose
}: PrivateStoreCheckProps) => {
  const [code, setCode] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  return (
    <Modal
      className="privateStoreCheck"
      open={!!pdvRef}
      confirmBtn={{
        disabled: code.length === 0,
        label: t('privateStoreCheck.buttons.validate'),
        onClick: async () => {
          const isValid = await PdvApi.checkPdvCode(pdvRef, code);

          if (isValid) {
            onValidated();
          } else {
            setIsInvalid(true);
          }
        }
      }}
      cancelBtn={{
        label: t('privateStoreCheck.buttons.cancel'),
        onClick: () => {
          onClose();
        }
      }}
      onClose={() => {
        onClose();
      }}
    >
      <>
        <h1>{t('privateStoreCheck.title')}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: t('privateStoreCheck.content')
          }}
        />
        <FormInput
          className="privateStoreCheck__input"
          label={`${t('privateStoreCheck.label')} :`}
          id="code"
          type="password"
          errorMessage={isInvalid ? t('privateStoreCheck.error') : ''}
          placeholder={t('privateStoreCheck.label')}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const target = event?.target as HTMLInputElement;

            setCode(target.value);
          }}
          value={code}
        />
      </>
    </Modal>
  );
};

export default PrivateStoreCheck;
