export const Styles: Array<google.maps.MapTypeStyle> = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'landscape',
    stylers: [
      {
        hue: '#FFBB00'
      },
      {
        saturation: 43.4
      },
      {
        lightness: 37.6
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: 'road.highway',
    stylers: [
      {
        hue: '#FFC200'
      },
      {
        saturation: -61.8
      },
      {
        lightness: 45.6
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: 'road.arterial',
    stylers: [
      {
        hue: '#FF0300'
      },
      {
        saturation: -100
      },
      {
        lightness: 51.2
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: 'road.local',
    stylers: [
      {
        hue: '#FF0300'
      },
      {
        saturation: -100
      },
      {
        lightness: 52
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: 'water',
    stylers: [
      {
        hue: '#0078FF'
      },
      {
        saturation: -13.2
      },
      {
        lightness: 2.4
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: 'poi',
    stylers: [
      {
        hue: '#00FF6A'
      },
      {
        saturation: -1.0989012
      },
      {
        lightness: 11.2
      },
      {
        gamma: 1
      }
    ]
  }
];
