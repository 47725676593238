import React, { ChangeEvent, useState } from 'react';
import classnames from 'classnames';
import './style.scss';
import { ReactComponent as Eyes } from '@/assets/images/icons/eyes.svg';
import Loader from '@/components/atoms/loader';
import t from '@/lib/i18n';
import CommonSprite from '@/assets/images/sprites/common.svg';

export type FormInputProps = {
  id?: string;
  name?: string;
  label?: string;
  className?: string;
  buttonLabel?: string;
  validationItem?: boolean;
  picto?: string;
  pictoSize?: string;
  size?: 'small' | 'medium' | 'large';
  type?: 'date' | 'text' | 'password' | 'tel' | 'number';
  value?: string;
  maxLength?: number;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  isLoading?: boolean;
  withEyes?: boolean;
  sendValue?: (e: string) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  message?: string | JSX.Element;
  extraAttributes?: { [key: string]: string | number | boolean };
  labelTooltip?: string | JSX.Element;
  autoComplete?: 'off' | 'on' | string;
  itemIsSentByParent?: boolean;
  readOnly?: boolean;
};

const FormInput = React.forwardRef(
  (
    {
      id,
      className,
      label,
      picto,
      pictoSize = '25',
      buttonLabel,
      size = 'small',
      type = 'text',
      value,
      isLoading = false,
      maxLength,
      placeholder = '',
      autoFocus = false,
      disabled = false,
      validationItem = false,
      withEyes = true,
      onChange = (e: ChangeEvent<HTMLInputElement>) => null,
      onFocus = (e: ChangeEvent<HTMLInputElement>) => null,
      sendValue,
      errorMessage,
      message,
      name,
      extraAttributes,
      onBlur,
      labelTooltip,
      autoComplete,
      itemIsSentByParent = false,
      readOnly = false
    }: FormInputProps,
    ref: any
  ) => {
    const [inputValue, setInputValue] = useState<string | null>(value || null);
    const [showPassword, setShowPassword] = useState(false);
    const [itemIsSent, setItemIsSent] = useState(false);
    const background = picto
      ? {
          backgroundImage: `url(${picto})`,
          backgroundSize: `${pictoSize}px`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '10px center'
        }
      : {};

    let inputType = type;

    if (type === 'password' && showPassword) {
      inputType = 'text';
    }

    const labelValidationItem = itemIsSent
      ? t('common.remove')
      : t('common.add');

    return (
      <div
        className={classnames(
          className,
          'formInput',
          `formInput--${type}`,
          `formInput--${size}`,
          {
            'formInput--hasError': !!errorMessage,
            'formInput--picto': !!picto,
            'formInput--validationItem': validationItem,
            'formInput--hasButton': !!buttonLabel || validationItem
          }
        )}
      >
        {label && (
          <label className="formInput__label" htmlFor={id}>
            {label}
            {labelTooltip}
          </label>
        )}
        <div className="formInput__container">
          {(buttonLabel || validationItem) && !isLoading && (
            <button
              className="formInput__button"
              onClick={() => {
                if ((validationItem || itemIsSentByParent) && inputValue) {
                  setItemIsSent((item) => !item);
                }
                sendValue && inputValue && sendValue(inputValue);
              }}
            >
              {validationItem && itemIsSent && !errorMessage && (
                <svg className="formInput__picto_check" width={20} height={20}>
                  <use xlinkHref={`${CommonSprite}#checkBold`} />
                </svg>
              )}
              {validationItem && itemIsSent && errorMessage && (
                <svg className="formInput__picto_wrong" width={20} height={20}>
                  <use xlinkHref={`${CommonSprite}#cross-bold`} />
                </svg>
              )}
              {!validationItem ? buttonLabel : labelValidationItem}
            </button>
          )}
          {isLoading && <Loader className="formInput__loader" size="small" />}
          <input
            style={background}
            className="formInput__input"
            id={id}
            name={name}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                sendValue && sendValue(e.currentTarget.value);
              }
            }}
            onBlur={(e) => {
              setInputValue(e.currentTarget.value);
              if (onBlur) {
                onBlur(e);
              }
            }}
            autoFocus={autoFocus}
            type={inputType}
            disabled={validationItem || disabled}
            defaultValue={value}
            placeholder={placeholder}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (inputType === 'number' && isNaN(Number(e.target.value)))
                return;
              if (buttonLabel || validationItem) {
                setInputValue(e.currentTarget.value);
              }
              onChange(e);
            }}
            ref={ref}
            maxLength={maxLength}
            autoComplete={autoComplete}
            readOnly={readOnly}
            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
              if (onFocus) onFocus(e);
            }}
            {...extraAttributes}
          />
          {type === 'password' && withEyes && (
            <Eyes
              className={classnames('formInput__picto_eyes', {
                'fill--red': errorMessage?.length && !showPassword,
                'fill--back': !errorMessage?.length && !showPassword,
                'fill--green': showPassword
              })}
              width={25}
              height={25}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            />
          )}
        </div>
        {errorMessage && (
          <div className="formInput__errorMessage">{errorMessage}</div>
        )}
        {!errorMessage && message && (
          <div className="formInput__message">{message}</div>
        )}
      </div>
    );
  }
);

export default FormInput;
