import { useDispatch } from 'react-redux';
import { useState } from 'react';

type Data = { [key: string]: any };

declare global {
  interface Window {
    didomiOnReady: any;
  }
}

class Event {
  dispatch: any;

  constructor(dispatch: any) {
    this.dispatch = dispatch;
  }

  send(name: string, data: Data = {}) {
    if (typeof window === 'undefined') return;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(() => {
      self.dispatch({
        type: `event_${name}`,
        payload: data
      });
    });
  }
}

const useEvent = () => {
  const dispatch = useDispatch();
  const [event] = useState(new Event(dispatch));

  return event;
};

export default useEvent;
