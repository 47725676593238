import React, { useState, useRef, HTMLAttributes } from 'react';
import './style.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classnames from 'classnames';

export type ImageProps = {
  style?: React.CSSProperties;
  className?: string;
  src: string;
  alt: string;
  width?: string | number | null;
  height?: string | number | null;
  lazy?: boolean;
};

const Image = ({
  className,
  src,
  alt,
  width,
  height,
  lazy = true
}: ImageProps) => {
  const [isHidden, setIsHidden] = useState(false);
  const lazyLoadImgEl = useRef<HTMLSpanElement>(null);
  const image = (
    <img
      className={classnames('image', className)}
      src={src}
      alt={alt}
      width={width || 'auto'}
      height={height || 'auto'}
    />
  );

  const afterLoad = () => {
    const imgEl = lazyLoadImgEl.current?.children[0] as HTMLImageElement;

    if (
      imgEl?.className === 'image productList__banner' &&
      imgEl?.naturalWidth === 1
    ) {
      setIsHidden(true);
    }
  };

  return (
    <>
      {lazy && (
        <>
          <LazyLoadImage
            className={classnames('image', className, { hidden: isHidden })}
            src={src}
            alt={alt}
            width={width || 'auto'}
            height={height || 'auto'}
            afterLoad={afterLoad}
            wrapperProps={
              {
                style: {
                  display: 'inline'
                },
                ref: lazyLoadImgEl
              } as HTMLAttributes<HTMLSpanElement>
            }
          />
          <noscript>{image}</noscript>
        </>
      )}
      {!lazy && image}
    </>
  );
};

export default Image;
