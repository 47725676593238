import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './style.scss';
import classnames from 'classnames';
import getConfigValue from '@/lib/config';
import useClickOut from '@/lib/hooks/useClickOut';
import Image from '@/components/atoms/image';
import Button from '@/components/atoms/button';
import Banner from '@/components/atoms/banner';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import _debounce from 'lodash/debounce';
import t from '@/lib/i18n';
import { escapeRegExp } from '@/lib/utils/stringUtils';
import MapsApi from '@/lib/api/google/maps';
import PdvApi from '@/lib/api/pdv';
import PdvModel, { TYPE_MATCHING } from '@/lib/model/pdv';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { Styles } from '@/lib/utils/googleMap';
import PinOn from '@/assets/images/pin-on.png';
import PinOff from '@/assets/images/pin-off.png';
import CommonSprite from '@/assets/images/sprites/common.svg';
import StoreLocatorSprite from '@/assets/images/sprites/storeLocator.svg';
import NoResult from '@/assets/images/no-result.png';
import { setPdvCookie } from '@/lib/utils/pdv';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_CHECKED_PDV } from '@/store/storeLocator/actions';
import { StoreLocatorStateType } from '@/store/storeLocator/reducer';
import useEvent from '@/lib/hooks/useEvent';
import { useDeepCompareEffect } from 'react-use';
import PrivateStoreCheck from './privateStoreCheck';

export type StoreLocatorProps = {
  withInStore?: boolean;
  withDrive?: boolean;
  withDelivery?: boolean;
  redirect?: {
    auto?: boolean;
    url?: string | null;
    onSelect?: (pdv: PdvModel) => void;
  };
};

type MapCenter =
  | {
      lat: number;
      lng: number;
    }
  | undefined;

const gmapKey = getConfigValue('GOOGLE_MAPS_KEY', '').toString();

export const TYPE_IN_STORE = 'in-store';
export const TYPE_DRIVE = 'drive';
export const TYPE_DELIVERY = 'delivery';

const libraries: Libraries = ['places'];

const StoreLocator = ({
  withInStore = true,
  withDrive = true,
  withDelivery = true,
  redirect
}: StoreLocatorProps) => {
  let typeInStore = TYPE_DRIVE;

  if (!withInStore && withDrive) {
    typeInStore = TYPE_DRIVE;
  } else if (!withInStore && !withDrive) {
    typeInStore = TYPE_DELIVERY;
  }

  useLoadScript({
    googleMapsApiKey: gmapKey,
    libraries
  });

  const [clickOutRef, clickOutHandler] = useClickOut();
  const [pdvCodeCheck, setPdvCodeCheck] = useState<PdvModel | null>(null);
  const [storeType, setStoreType] = useState(typeInStore);
  const [keyword, setKeyword] = useState('');
  const [highlight, setHighlight] = useState({
    pdvRef: '',
    isMap: false
  });
  const [results, setResults] = useState<{
    mapCenter: MapCenter;
    pdvs: Array<PdvModel>;
    suggestions: Array<string>;
    noResults: boolean;
  }>({
    mapCenter: undefined,
    pdvs: [],
    suggestions: [],
    noResults: false
  });
  const dispatch = useDispatch();
  const event = useEvent();

  const { checkedPdvs } = useSelector(
    ({ storeLocator }: { storeLocator: StoreLocatorStateType }) => {
      return {
        checkedPdvs: storeLocator.checked
      };
    }
  );

  const translateEvent = (evt: string) => {
    let type;
    switch (evt) {
      case 'drive':
        type = 'drive';
        break;
      case 'in-store':
        type = 'magasin';
        break;
      case 'delivery':
        type = 'lad';
        break;
    }
    return type;
  };

  useEffect(() => {
    event.send('storeLocator', {
      type: 'service',
      storeType: translateEvent(storeType)
    });
  }, [storeType, event]);

  const selectStore = (pdv: PdvModel) => {
    if ((!redirect?.auto ?? false) && !!redirect?.onSelect) {
      redirect.onSelect(pdv);

      return;
    }

    setPdvCookie(pdv);

    window.location.href = redirect?.url ?? '/home';
  };

  const resetResults = useCallback(() => {
    setResults({
      mapCenter: undefined,
      pdvs: [],
      suggestions: [],
      noResults: false
    });
  }, []);

  const reset = useCallback(() => {
    setKeyword('');
    resetResults();
  }, [resetResults]);

  clickOutHandler(reset);

  const highlightByKeyword = useMemo(
    () =>
      _debounce(
        (pdvRef: string, isMap = false) => {
          setHighlight({
            pdvRef,
            isMap
          });
        },
        100,
        { maxWait: 1000 }
      ),
    []
  );

  const searchByKeyword = useMemo(
    () =>
      _debounce(
        async (searchKeyword: string, searchStoreType: string) => {
          if (!searchKeyword || searchKeyword.length < 3) {
            return;
          }

          let pdvs: Array<PdvModel> = [];
          const suggestions: Array<string> = [];
          let mapCenter = null;
          let noResults = true;
          const adresses = await MapsApi.getAdresses(searchKeyword);
          if (adresses) {
            const adress = adresses?.results?.[0];
            mapCenter = adress?.geometry?.location;
            if (searchStoreType === TYPE_DELIVERY) {
              pdvs = await PdvApi.getPdvsByArea(mapCenter, false, 10, 10, true);
              const { lat, lng } = mapCenter;
              const geocodeAddress = await MapsApi.geocode(lat, lng);
              const postalCodeLocality = getCodePostal(geocodeAddress);
              const pdvsDelivery = await PdvApi.getPdvsDelivery(
                postalCodeLocality?.postalCode,
                postalCodeLocality?.locality,
                10
              );
              pdvs = findIfPdvIsDeliveryMode(pdvs, pdvsDelivery);
            } else {
              pdvs = await PdvApi.getPdvsByArea(
                mapCenter,
                searchStoreType === TYPE_DRIVE,
                10,
                10
              );
            }
          }

          noResults = pdvs.length === 0 && suggestions.length === 0;

          setResults({
            mapCenter,
            pdvs,
            suggestions,
            noResults
          });

          document.querySelector('.storeLocator__suggestions')?.scrollTo(0, 0);
        },
        300,
        { maxWait: 1000 }
      ),
    []
  );

  const findIfPdvIsDeliveryMode = (
    pdvsByArea: PdvModel[],
    pdvsDelivery: PdvModel[]
  ) => {
    const refSet = new Set(pdvsByArea.map((item) => item?.ref));
    return pdvsDelivery.filter((item) => refSet.has(item.ref));
  };

  const getCodePostal = (data: any) => {
    const postalCodeItem = data?.results?.find((result: any) =>
      result?.types?.includes('street_address')
    );

    if (postalCodeItem) {
      const postalCode = postalCodeItem?.address_components?.find(
        (component: any) => component?.types?.includes('postal_code')
      );
      const locality = postalCodeItem?.address_components?.find(
        (component: any) => component?.types?.includes('locality')
      );

      if (postalCode && locality) {
        return {
          postalCode: postalCode?.long_name,
          locality: locality?.long_name
        };
      }
    }
    return null;
  };

  const searchByGeolocation = async (lat: number, lng: number) => {
    let searchValue = '';
    let postalCode;
    let locality;
    const addressData = await MapsApi.geocode(lat, lng);

    if (addressData?.results?.length) {
      const addressComponents = addressData.results[0].address_components;
      for (const component of addressComponents) {
        if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        }
        if (component.types.includes('locality')) {
          locality = component.long_name;
        }
      }
      searchValue = `${locality} ${postalCode}`;
    }

    setKeyword(searchValue);
  };

  useDeepCompareEffect(() => {
    searchByKeyword(keyword, storeType);
  }, [keyword, storeType]);

  useEffect(() => {
    if (!highlight?.isMap) {
      return;
    }

    const elt = document.querySelector(
      '.storeLocator__suggestion--highlight'
    ) as HTMLElement;

    document
      .querySelector('.storeLocator__suggestions')
      ?.scrollTo(0, elt.offsetTop - 100);
  }, [highlight]);

  return (
    <>
      <div className="storeLocator" ref={clickOutRef}>
        <div className="storeLocator__modes">
          {withDrive && (
            <div
              onClick={() => {
                setStoreType(TYPE_DRIVE);
                resetResults();
              }}
              className={classnames({
                storeLocator__mode: true,
                'storeLocator__mode--active': storeType === TYPE_DRIVE
              })}
              id="storeLocator__modeWithDriveId"
            >
              <svg height="35" width="35">
                <use xlinkHref={`${StoreLocatorSprite}#drive`} />
              </svg>
              <span className="storeLocator__mode_label">
                {t('storeLocatore.tabs.drive')}
              </span>
              <span className="storeLocator__mode_label--mobile">
                {t('storeLocatore.tabs.drive.mobile')}
              </span>
            </div>
          )}
          {withDelivery && (
            <div
              onClick={() => {
                setStoreType(TYPE_DELIVERY);
                resetResults();
              }}
              className={classnames({
                storeLocator__mode: true,
                'storeLocator__mode--active': storeType === TYPE_DELIVERY
              })}
              id="storeLocator__modeWithDeliveryId"
            >
              <svg height="35" width="35">
                <use xlinkHref={`${StoreLocatorSprite}#delivery`} />
              </svg>
              <span className="storeLocator__mode_label">
                {t('storeLocatore.tabs.delivery')}
              </span>
              <span className="storeLocator__mode_label--mobile">
                {t('storeLocatore.tabs.delivery.mobile')}
              </span>
            </div>
          )}
          {withInStore && (
            <div
              onClick={() => {
                setStoreType(TYPE_IN_STORE);
                resetResults();
              }}
              className={classnames({
                storeLocator__mode: true,
                'storeLocator__mode--active': storeType === TYPE_IN_STORE
              })}
              id="storeLocator__modeWithInStoreId"
            >
              <svg height="35" width="35">
                <use xlinkHref={`${StoreLocatorSprite}#store`} />
              </svg>
              <span className="storeLocator__mode_label">
                {t('storeLocatore.tabs.store')}
              </span>
              <span className="storeLocator__mode_label--mobile">
                {t('storeLocatore.tabs.store.mobile')}
              </span>
            </div>
          )}
        </div>
        <div className="storeLocator__content">
          <div className="storeLocator__search" id="storeLocator__searchId">
            <input
              className="storeLocator__input"
              type="text"
              id="storeLocator__inputSearchId"
              placeholder={t('storeLocatore.input.placeholder')}
              aria-label={t('storeLocatore.input.placeholder')}
              onChange={(e) => {
                resetResults();
                setKeyword(e.target.value);
              }}
              value={keyword}
            />
            <div
              className="storeLocator__geolocation"
              id="storeLocator__geolocationId"
              onClick={() => {
                event.send('storeLocator', {
                  type: 'geolocation',
                  keyword
                });
                window.navigator.geolocation.getCurrentPosition((position) => {
                  searchByGeolocation(
                    position.coords.latitude,
                    position.coords.longitude
                  );
                });
              }}
            >
              <svg height="20" width="20">
                <use xlinkHref={`${StoreLocatorSprite}#geolocation`} />
              </svg>
            </div>
            <div
              className="storeLocator__submit"
              onClick={() => {
                searchByKeyword(keyword, storeType);
                event.send('storeLocator', {
                  type: 'search',
                  keyword
                });
              }}
            >
              <svg className="fill--white" height="24" width="24">
                <use xlinkHref={`${CommonSprite}#loupe`} />
              </svg>
            </div>
          </div>
          {(results.pdvs.length > 0 || results.suggestions.length > 0) && (
            <div className="storeLocator__results" id="storeLocator__resultsId">
              <div
                className="storeLocator__suggestions"
                id="storeLocator__suggestionsId"
              >
                {results.pdvs.map((pdv: PdvModel, key) => {
                  const pdvKey = `key-${pdv.ref}`;

                  return (
                    <div
                      className={classnames('storeLocator__suggestion', {
                        'storeLocator__suggestion--highlight':
                          pdv.ref === highlight.pdvRef
                      })}
                      key={pdvKey}
                      id={`${pdvKey}-storeLocator__suggestionId`}
                      onMouseOver={() => {
                        highlightByKeyword(pdv.ref, false);
                      }}
                      onFocus={() => {
                        highlightByKeyword(pdv.ref, false);
                      }}
                      onClick={() => {
                        event.send('storeLocator', {
                          type: 'select',
                          storeInfo: `${pdv.ref}::${pdv.name}`
                        });
                        event.send('storeLocator', {
                          type: 'position',
                          key
                        });
                        reset();

                        if (pdv.isPrivate && !checkedPdvs.includes(pdv.ref)) {
                          setPdvCodeCheck(pdv);
                        } else {
                          selectStore(pdv);
                        }
                      }}
                    >
                      <div
                        className="storeLocator__suggestion_content"
                        id={`storeLocator__suggestion_contentId-${pdv.name}`}
                      >
                        <span
                          id={`storeLocator__suggestion_name-${pdv.name}`}
                          className="storeLocator__suggestion_name"
                        >
                          {pdv.name}
                        </span>
                        {pdv.distance && (
                          <span className="storeLocator__suggestion_distance">
                            {(pdv.distance / 1000).toFixed(1)}km
                          </span>
                        )}
                        {pdv.address && (
                          <div className="storeLocator__suggestion_address">
                            {pdv.address.address1}
                            <br />
                            {pdv.address.zipCode} {pdv.address.city}
                          </div>
                        )}
                      </div>
                      <div className="storeLocator__suggestion_services">
                        {pdv.types.length > 0 &&
                          pdv.types.map((type, index: number) => {
                            const pdvTypeKey = `service-${pdv.ref}-${index}`;

                            return (
                              <div
                                key={pdvTypeKey}
                                className="storeLocator__suggestion_service"
                              >
                                <svg height="26" width="26">
                                  <use
                                    xlinkHref={`${StoreLocatorSprite}#${
                                      pdv.isPrivate
                                        ? 'lock'
                                        : TYPE_MATCHING[type]
                                    }`}
                                  />
                                </svg>
                                {t(`pdv.type.${type}`)}
                              </div>
                            );
                          })}
                        {(pdv.hasService('ess') || pdv.hasService('ess24')) && (
                          <div className="storeLocator__suggestion_service">
                            <svg height="26" width="26">
                              <use
                                xlinkHref={`${StoreLocatorSprite}#gasoline`}
                              />
                            </svg>
                            {t(
                              `storeLocatore.pdv.service.${
                                pdv.hasService('ess24') ? 'ess24' : 'ess'
                              }`
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                {results.suggestions.map(
                  (suggestion: string, index: number) => {
                    const suggestionKey = `suggestion-${index}`;
                    const highlightedSuggestion = suggestion.replace(
                      new RegExp(`(${escapeRegExp(keyword)})`, 'gi'),
                      `<span>${keyword}</span>`
                    );

                    return (
                      <div
                        key={suggestionKey}
                        className="storeLocator__suggestion storeLocator__suggestion--other"
                        onClick={() => {
                          setKeyword(suggestion);
                        }}
                        dangerouslySetInnerHTML={{
                          __html: highlightedSuggestion
                        }}
                      />
                    );
                  }
                )}
              </div>
              {results.pdvs.length > 0 && storeType !== TYPE_DELIVERY && (
                <GoogleMap
                  mapContainerClassName="storeLocator__map"
                  center={results.mapCenter}
                  zoom={11}
                  options={{
                    fullscreenControl: false,
                    mapTypeControl: false,
                    zoomControl: false,
                    streetViewControl: false,
                    maxZoom: 15,
                    disableDefaultUI: true,
                    styles: Styles
                  }}
                >
                  {results.pdvs.map((pdv: PdvModel) => {
                    if (!pdv.address) {
                      return null;
                    }

                    const markerKey = `marker-${pdv.ref}`;

                    return (
                      <Marker
                        key={markerKey}
                        position={{
                          lat: pdv.address.latitude,
                          lng: pdv.address.longitude
                        }}
                        icon={pdv.ref === highlight.pdvRef ? PinOn : PinOff}
                        onMouseOver={(e: google.maps.MapMouseEvent) => {
                          highlightByKeyword(pdv.ref, true);
                        }}
                      />
                    );
                  })}
                </GoogleMap>
              )}
            </div>
          )}
          {results.noResults && storeType === TYPE_DELIVERY && (
            <div className="storeLocator__no-delivery">
              <Image src={NoResult} alt="" lazy={false} width={190} />
              <div className="storeLocator__no-delivery_content">
                <h3>{t('storeLocatore.no-delivery.title')}</h3>
                <div
                  className="storeLocator__no-delivery_message"
                  dangerouslySetInnerHTML={{
                    __html: t('storeLocatore.no-delivery.content')
                  }}
                />
                <Button
                  onClick={() => {
                    setStoreType(TYPE_DRIVE);
                  }}
                >
                  {t('storeLocatore.no-delivery.button')}
                </Button>
              </div>
            </div>
          )}
          {results.noResults && storeType !== TYPE_DELIVERY && (
            <Banner
              className="storeLocator__no-result"
              message={t('storeLocatore.no-results')}
            />
          )}
        </div>
      </div>
      {pdvCodeCheck && (
        <PrivateStoreCheck
          pdvRef={pdvCodeCheck.ref}
          onClose={() => {
            setPdvCodeCheck(null);
          }}
          onValidated={() => {
            dispatch({
              type: ADD_CHECKED_PDV,
              payload: { pdv: pdvCodeCheck.ref }
            });
            selectStore(pdvCodeCheck);
            setPdvCodeCheck(null);
          }}
        />
      )}
    </>
  );
};

export default StoreLocator;
